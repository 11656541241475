<template>
  <div class="flex flex-col">
    <Title :pages="pages">
      <Button
        as="router-link"
        v-if="workflowImport?.workflow_id"
        :to="{
          name: 'workflows.view',
          params: { workflow: workflowImport?.workflow_id },
        }"
      >
        Workflow
      </Button>
      <Button @click="remove = true" status="danger" class="ml-3">
        Remove
      </Button>
    </Title>

    <div v-if="loading">loading....</div>
    <div v-else class="flex-1 flex flex-col">
      <div class="py-3 px-4 sm:px-6 lg:px-8">
        <Card>
          <CardItem
            heading="File Name"
            :icon="CalendarIcon"
            :message="workflowImport.name"
          >
            <template #secondary-heading>
              <p
                :class="{
                  'bg-green-100 text-green-800':
                    workflowImport.status === 'Completed',
                  'bg-gray-100 text-gray-800':
                    workflowImport.status === 'Draft',
                  'bg-blue-100 text-blue-800':
                    workflowImport.status === 'Processing',
                  'bg-red-100 text-red-800':
                    workflowImport.status === 'ProcessFaileding',
                }"
                class="
                  px-2
                  inline-flex
                  text-xs
                  leading-5
                  font-semibold
                  rounded-full
                "
              >
                {{ workflowImport.status }}
              </p>
            </template>

            <template #secondary-message>
              <Timestamp :value="workflowImport.created_at" />
            </template>
          </CardItem>

          <CardItem
            heading="File Size"
            :message="`${workflowImport.size} bytes`"
          />

          <CardItem heading="Mime Type" :message="workflowImport.mime" />

          <CardItem heading="Last updated">
            <Timestamp :value="workflowImport.updated_at" />
          </CardItem>
        </Card>

        <div>
          <h3 class="p-3 font-bold">Logs</h3>
          <div class="flow-root">
            <ul role="list" class="-mb-8">
              <li v-for="(log, logIndex) in workflowImport.logs" :key="log.id">
                <div class="relative pb-8">
                  <span
                    v-if="logIndex !== workflowImport.logs.length - 1"
                    class="
                      absolute
                      top-4
                      left-4
                      -ml-px
                      h-full
                      w-0.5
                      bg-gray-200
                    "
                    aria-hidden="true"
                  />
                  <div class="relative flex space-x-3">
                    <div>
                      <span
                        :class="[
                          { 'bg-blue-500': log.type === 'Info' },
                          { 'bg-red-500': log.type === 'Error' },
                          { 'bg-green-500': log.type === 'Success' },
                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                        ]"
                      >
                        <QuestionMarkCircleIcon
                          v-if="log.type === 'Error'"
                          class="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                        <CheckIcon
                          v-else-if="log.type === 'Success'"
                          class="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                        <ChipIcon
                          v-else
                          class="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div
                      class="
                        min-w-0
                        flex-1
                        pt-1.5
                        flex
                        justify-between
                        space-x-4
                      "
                    >
                      <div>
                        <p class="text-sm text-gray-500">
                          {{ log.title }}
                          <a
                            :href="log.href"
                            class="font-medium text-gray-900"
                            >{{ log.message }}</a
                          >
                        </p>
                      </div>
                      <div
                        class="
                          text-right text-sm
                          whitespace-nowrap
                          text-gray-500
                        "
                      >
                        <Timestamp :value="log.created_at" />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        :open="remove"
        status="danger"
        title="Remove Imports"
        action="Remove"
        :loading="removing"
        @close="remove = false"
        @submit="handleRemove"
      >
        <p>Do you want to remove {{ workflowImport.name }}?</p>
      </Modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, inject, computed } from "vue";
import { CheckIcon, ChipIcon, CalendarIcon } from "@heroicons/vue/solid";
import { QuestionMarkCircleIcon } from "@heroicons/vue/solid";
import { useRoute, useRouter } from "vue-router";
import card from "@/components/common/card";
import Title from "@/components/common/Title";
import Modal from "@/components/common/Modal";
import Button from "@/components/common/Button";
import Timestamp from "@/components/common/Timestamp";

export default defineComponent({
  mixins: [card],

  components: {
    Title,
    Modal,
    Button,
    Timestamp,
    CheckIcon,
    ChipIcon,
    QuestionMarkCircleIcon,
  },
  setup() {
    const $axios = inject("axios");

    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const workflowImport = ref(null);

    const remove = ref(false);
    const removing = ref(false);

    const handleRemove = async () => {
      removing.value = true;

      try {
        await $axios.delete(
          `/workflows-imports/${route.params.workflowImport}`
        );

        remove.value = true;
        router.push({ name: "workflows.imports.index" });
      } catch (error) {
        //
      }

      removing.value = false;
    };

    const pages = computed(() => [
      {
        name: "Workflow Imports",
        href: "workflows.imports.index",
        current: false,
      },
      {
        name: workflowImport.value ? `${workflowImport.value.name}` : "View",
        href: "workflows.imports.view",
        current: true,
      },
    ]);

    (async () => {
      loading.value = true;

      try {
        const { data: item } = await $axios.get(
          `/workflows-imports/${route.params.workflowImport}`
        );

        workflowImport.value = item.data;
      } catch (error) {
        //
      }

      loading.value = false;
    })();

    return {
      pages,
      remove,
      loading,
      removing,
      CalendarIcon,
      handleRemove,
      workflowImport,
    };
  },
});
</script>
