<template>
  <li class="block hover:bg-gray-50">
    <div class="px-4 py-4 sm:px-6">
      <div class="flex items-center justify-between">
        <p class="text-sm font-medium text-indigo-600 truncate">
          <slot name="heading">
            {{ heading }}
          </slot>
        </p>
        <div class="ml-2 flex-shrink-0 flex">
          <slot name="secondary-heading">
            {{ secondaryHeading }}
          </slot>
        </div>
      </div>
      <div class="mt-2 sm:flex sm:justify-between">
        <div class="sm:flex">
          <p class="flex items-center text-sm text-gray-500">
            <slot>
              {{ message }}
            </slot>
          </p>
        </div>
        <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
          <component
            v-if="icon"
            :is="icon"
            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <p>
            <slot name="secondary-message">
              {{ secondaryMessage }}
            </slot>
          </p>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: Function,
    heading: String,
    message: String,
    secondaryHeading: String,
    secondaryMessage: String,
  },
});
</script>
