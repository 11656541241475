import { defineComponent } from "vue";
import Card from "./Card.vue";
import CardItem from "./CardItem.vue";

export default defineComponent({
  components: {
    Card,
    CardItem,
  },
});
