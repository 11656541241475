<template>
  <div class="shadow rounded">
    <ul role="list" class="divide-y divide-gray-200">
      <slot />
    </ul>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  //
});
</script>
